@import '/theme.css';
@import '/utilities.css';

.component {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  font-family: fontFamilyAlt;
  font-weight: fontWeightBold;
  font-size: fontSizeL;
  line-height: lineHeightHeading;
  overflow-y: auto;
  padding-top: headerHeight;
  padding-bottom: calc(2 * headerHeight);
  background: cBlueDark;

  @media viewportLg {
    padding-top: 0;
    padding-bottom: 0;
    font-family: fontFamilyBase;
    font-weight: fontWeightRegular;
    font-size: fontSizeXs;
    overflow-y: hidden;
  }

  @media viewportXl {
    font-size: fontSizeS;
  }

  & > .navigationList {
    margin-top: auto;
    margin-bottom: auto;
  }
}

.links {
  display: flex;
  align-items: center;
  flex-direction: column;
  margin: 0;
  padding-left: 0;
  list-style-type: none;

  & > :not(:last-child) {
    flex: 0 0 auto;
    margin-bottom: sizeXl;
  }

  @media viewportLg {
    flex-direction: row;

    & > :not(:last-child) {
      margin-right: sizeMd;
      margin-bottom: 0;
    }
  }

  @media viewportXl {

    & > :not(:last-child) {
      margin-right: sizeX2l;
    }
  }
}

.link {
  padding: sizeX3s 0;
}
