@import '/theme.css';
@import '/utilities.css';

.componentBase {
  height: headerHeight;
  background-color: cBlueDark;
  color: cWhite;

  & > .container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    width: 100%;
    max-width: calc(containerWidthLg + gutterS * 2);
    margin: 0 auto;

    @media viewportSm {
      max-width: calc(containerWidthLg + gutterM * 2);
    }

    @media viewportLg {
      max-width: calc(containerWidthLg + gutterL * 2);
    }
  }

  & a {
    color: inherit;
  }
}

.inner {
  position: relative;
  display: flex;
  align-items: center;
  background-color: cBlueDark;
  padding: sizeX3s gutterS;

  @media viewportMd {
    padding: sizeX3s gutterM;
  }

  @media viewportLg {
    padding: sizeX3s gutterL;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
  }

  & > .logo {
    position: relative;
    z-index: 1;
    margin-right: auto;

    @media viewportLg {
      margin-bottom: sizeX4s;
    }
  }
}

.content {
  display: flex;
  align-items: center;

  & > .navigation {
    position: absolute;
    top: 0;
    right: 0;
    height: 100vh;
    left: 0;

    &[aria-hidden='true'] {
      display: none;
    }

    &[aria-hidden='false'] {
      display: block;
      animation: reveal-navigation easeOutExpressive durationM;
    }

    @media viewportLg {
      position: static;
      height: auto;
      margin-left: sizeMd;

      &[aria-hidden='true'] {
        display: block;
      }
    }
  }

  & > .hamburger {
    position: absolute;
    z-index: 2;
    right: gutterS;

    @media viewportMd {
      right: gutterM;
    }

    @media viewportLg {
      position: static;
    }
  }
}

.logo {
  font-family: fontFamilyAlt;
  font-weight: fontWeightRegular;
  font-size: fontSizeM;
  display: flex;
  flex-wrap: wrap;
  align-items: center;

  @media viewportSm {
    flex-wrap: nowrap;
  }
}

@keyframes reveal-navigation {
  from {
    opacity: 0;
    transform: translateY(calc(-1 * sizeLg));
  }
}
