@import '/theme.css';

.backgroundDefault {
  background: cWhite;
  color: cBlack;
}

.accentColorDefault {
  color: cBlueDark;
}

.backgroundGray {
  background: cGrayLight;
  color: cBlack;
}

.accentColorGray {
  color: cBlueDark;
}

.backgroundBrand {
  background: cBlueDark;
  color: cWhite;
}

.accentColorBrand {
  color: cBlueLight;
}
