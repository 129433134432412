@font-face {
  font-family: 'Graphik';
  src: url('/theme/fonts/Graphik-Regular.woff2') format('woff2'), url('/theme/fonts/Graphik-Regular.woff') format('woff');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Graphik';
  src: url('/theme/fonts/Graphik-Semibold.woff2') format('woff2'), url('/theme/fonts/Graphik-Semibold.woff') format('woff');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Produkt';
  src: url('/theme/fonts/Produkt-Super.woff2') format('woff2'), url('/theme/fonts/Produkt-Super.woff') format('woff');
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: 'Produkt';
  src: url('/theme/fonts/Produkt-Bold.woff2') format('woff2'), url('/theme/fonts/Produkt-Bold.woff') format('woff');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Produkt';
  src: url('/theme/fonts/Produkt-Light.woff2') format('woff2'), url('/theme/fonts/Produkt-Light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Journal';
  src: url('/theme/fonts/Journal.woff2') format('woff2'), url('/theme/fonts/Journal.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}
