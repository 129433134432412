@import '/theme.css';

.component {
  display: flex;
  flex-direction: column;
  height: 100%;
  position: relative;
  z-index: 0;

  & > * {
    width: 100%; /* Make IE11 behave */
  }

  & > .cookieBanner {
    z-index: 1;
  }
}

.header {
  flex-shrink: 0;
  position: fixed;
  z-index: 2;
  top: 0;
  left: 0;
  right: 0;
}

.main {
  position: relative;
  z-index: 1;
  flex: 1 0 auto;
  display: flex;
  flex-direction: column;
  padding-top: headerHeight;

  & > * {
    width: 100%; /* Make IE11 behave */
  }

  & > :first-child:last-child {
    margin-top: auto;
    margin-bottom: auto;
  }
}

.footer {
  flex-shrink: 0;
}
