@import '/theme.css';
@import '/utilities.css';

.component {
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  cursor: pointer;

  & rect {
    transition: transform easeInOutExpressive durationS, opacity easeInOutExpressive durationS;

    &:first-child {
      transform-origin: 10px 4.5px;
    }

    &:last-child {
      transform-origin: 10px 16.5px;
    }

    &:not(:first-child):not(:last-child) {
      transform-origin: 10px 10.5px;
    }
  }

  &[aria-expanded='false'] {
    &:--enter rect:first-child {
      transform: translateY(-2px);
    }

    &:--enter rect:last-child {
      transform: translateY(2px);
    }
  }

  &[aria-expanded='true'] {
    & rect:first-child {
      transform: translateY(6px) rotate(45deg);
    }

    & rect:last-child {
      transform: translateY(-6px) rotate(-45deg);
    }

    & rect:not(:first-child):not(:last-child) {
      opacity: 0;
      transform: rotate(45deg);
    }
  }

  @media viewportLg {
    display: none;
  }
}

.label {
  @apply --visuallyHidden;
}
