@import '/theme.css';

.component {
  display: inline-block;
  font-size: inherit;
  cursor: pointer;
}

.expandHitArea {
  position: relative;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }
}

.label {
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  color: cBlue;
  transition: color easeOutProductive durationXs;

  &:--enter {
    color: cBlueDark;
  }

  &.white {
    color: cWhite;

    &:--enter {
      color: cWhite;
    }
  }

  &.lightBlue {
    color: cBlueLight;

    &:--enter {
      color: cBlueLight;
    }
  }
}
